.c-form-field {
    $c: &;

    &__label {
        @apply .text-kbrv-tertiary;

        &.is-required {
            &:after {
                @apply .font-kbrv-primary;
            }
        }
    }
}
