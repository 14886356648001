.c-link {
    $c: &;

    @apply .font-kbrv-primary;

    &--primary {
        #{$c} {
            &__label {
                @apply .text-kbrv-tertiary;
            }
        }
        &:after {
            @apply .bg-kbrv-primary;
        }
    }

    &--secondary {
        #{$c} {
            &__label,
            &__icon .c-icon {
                @apply .text-kbrv-primary;
            }
        }
    }

    &--tertiary {
        &:hover,
        &:active,
        &.hover {
            @apply .border-kbrv-primary;
        }
    }
}
