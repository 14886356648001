// -----------------------------------------------------------------------------
// elements/heading
// -----------------------------------------------------------------------------

.h0,
h1,
h2,
h3,
h4,
h5,
h6 {
    @apply .font-kbrv-primary .font-bold .text-kbrv-tertiary;

    strong {
        @apply .text-kbrv-primary .font-bold;
    }

    .text-white & {
        @apply .text-white;
    }
}