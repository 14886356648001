.c-hero {
    $c: &;

    @apply .bg-kbrv-secondary;

    h1#{$c}__title {
        @apply .text-white;
    }

    &__sub-title {
        @apply .text-white;
    }

    &--alt {
        #{$c} {
            &__bottom {
                @apply .bg-kbrv-secondary;
            }
        }
    }
}
