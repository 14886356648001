// -----------------------------------------------------------------------------
// generics/base
// -----------------------------------------------------------------------------

::selection {
    @apply .bg-kbrv-primary;
}

.grid-template-related {
    grid-template-areas: 
        "header link"
        "list list";

    .grid-area-header {grid-area: header;}
    .grid-area-link {grid-area: link;}
    .grid-area-list {grid-area: list;}
}

.grid-columns-3-1 {
    grid-template-columns: 3fr 1fr;
}

.translate-y-calc-1_2 {
    @media (min-width: 1024px) {
        transform: translateY(calc((100% - 14.375rem) / -2));
    }
}

.shrink-0 {
    flex-shrink: 0;
}

.after-white:after {
    content: '';

    @apply .absolute .inset-0 .bg-white;

    top: 50%;
}

img.hover {
    transform: scale(1.1);
}

.top-1_2 {
    top: 50%;
}