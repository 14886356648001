.c-slider {
    $c: &;

    &__nav-button {
        &:hover,
        &:active {
            @apply .border-kbrv-secondary;

            .c-icon {
                @apply .text-kbrv-tertiary;
            }
        }

        &--alt {
            @apply .border-kbrv-primary; 

            .c-icon {
                @apply .text-kbrv-tertiary;
            }
        }
    }

    &__pager {
        @apply .font-kbrv-primary .text-lg .font-bold .h-12 .px-6 .rounded-full .border .border-kbrv-primary .flex .items-center .justify-center;

        &--current {@apply .text-kbrv-tertiary .pr-1.25;}
    }

    &__actions {
        position: absolute; bottom: 30px; right: 30px;

        .play,
        .pause {
            @apply .p-4 .rounded-full .hidden .cursor-pointer;

            &.active {
                @apply .block;
            }
        }
        
        .play {@apply .bg-kbrv-tertiary .text-kbrv-primary;}
        .pause {@apply .bg-kbrv-primary .text-kbrv-tertiary;}
    }
}
