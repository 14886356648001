.c-nav {
    $c: &;

    &--primary {
        #{$c} {
            &__list {
                @apply .flex .flex-col;

                @screen lg {
                    display: grid;
                }
            }

            &__item {
                @screen lg {
                    grid-row: 1;
                }
                &:after {
                    @apply .bg-kbrv-primary;
                }
            }
            
            &__item-wrapper {
                @screen lg {
                    @apply .flex .h-full .items-center
                }
            }

            &__item-inner {
                @screen lg {
                    &:hover,
                    &:active,
                    &.has-hover {
                        #{$c} {
                            &__label-text,
                            &__label-icon .c-icon {
                                @apply .text-kbrv-primary;
                            }
                        }
                    }
                }
            }

            &__sub-link {
                @screen lg {
                    &:after {
                        @apply .bg-kbrv-primary;
                    }

                    &:hover,
                    &:active {
                        #{$c} {
                            &__sub-label {
                                @apply .text-kbrv-primary;
                            }
                        }
                    }
                }
            }

            &__sub-label {
                @apply .font-kbrv-primary;
            }

            &__label-text {
                @apply .font-kbrv-primary;
            }
        }

        #navLang {
            right: -12px;

            #{$c} {
                &__sub-list {
                    @apply .flex-col .items-end;
                }
                &__sub-item {
                    @apply .w-15;

                    &:first-child #{$c}__sub-link {@apply .pt-5;}
                    &:last-child #{$c}__sub-link {@apply .pb-5;}
                }
                &__sub-link {
                    @apply .h-full .py-2.5 .border-b-0;

                    &:hover,
                    &:active {
                        @apply .bg-white;

                        &:after {
                            @apply .opacity-0;
                        }
                    }
                }
            }
        }
    }

    &--breadcrumb {
        #{$c} {
            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label-text {
                            @apply .text-kbrv-primary;
                        }
                    }
                }
            }
        }
    }

    &--legal {
        @apply .justify-center;

        #{$c} {
            &__link {
                &:hover,
                &:active {
                    #{$c} {
                        &__label-text {
                            @apply .text-kbrv-primary;
                        }
                    }
                }
            }

            &__label-text {
                @apply .text-lg .font-medium;
            }
        }
    }
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav {
    $c: c-nav;

    &--primary {
        .#{$c} {
            &__label-text {
                @apply .text-kbrv-tertiary;
            }
            &__label-icon {
                @apply .text-kbrv-tertiary;
            }

            &__sub-link {
                @apply .bg-kbrv-secondary .border-white .border-b-0;

                &:hover {
                    @apply .bg-kbrv-secondary;
                }
            }
            &__sub-label {
                @apply .text-white; 
            }
        }

        #navLang {
            .#{$c} {
                &__sub-link {
                    @apply .bg-kbrv-secondary .border-0;
    
                    &:hover {
                        @apply .bg-kbrv-secondary;
                    }
                }
                &__sub-label {
                    @apply .text-white; 
                }
            }
        }
    }

    &--breadcrumb {
        .#{$c} {
            &__item:after {
                @apply .text-kbrv-tertiary;
            }
            &__label-text {
                @apply .text-kbrv-tertiary;
            }
        }
    }
}

.c-page--altHeader {
    .hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after {
        @apply .bg-kbrv-tertiary;
    }
}