.c-presentation {
    $c: &;

    &__text-wrapper {
        @apply .opacity-100;
    }

    &__footer {
        @apply .border-kbrv-primary;

        &:before {
            @apply .bg-kbrv-tertiary;
        }
    }

    &:after {
        @apply .bg-kbrv-primary;
    }

    &.has-image {
        #{$c} {
            &__picture {
                &:after {
                    content: none;
                }
            }
        }
    }

    &:hover,
    &:active {
        #{$c} {
            &__footer {
                &:before {
                    @apply .w-full .bg-white;
                }
            }
        }
    }
}