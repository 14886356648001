.c-text {
    $c: &;

    &--medium {
        @apply .text-lg;
    }

    a {
        &:after {
            @apply .bg-kbrv-primary;
        }
    }

    ul {
        li {
            &:before {
                @apply .bg-kbrv-primary;
            }
        }
    }

    ol {
        li {
            &::marker {
                @apply .text-kbrv-primary;
            }
        }
    }
}
