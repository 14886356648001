section.teamMembers {
    .c-member {
        @apply .w-full .h-full .relative .text-0;

        &__wrapper {
            @apply .relative .h-full .overflow-hidden;
        }

        &__inner,
        &__overlay {
            @apply .p-10;
        }

        &__inner {
            @apply .relative .h-full .z-10 .bg-grey-lighter .transition-all .duration-300 .ease-in-out;
        }

        &__picture {
            width: 172px;
            height: 172px;

            @apply .relative .rounded-21 .overflow-hidden .z-10 .transition-picture .duration-700 .ease-in-out;

            &:after {
                content: '';

                @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .bg-secondary .transition-all .duration-300 .ease-in-out;
            }
        }

        &__body {
            @apply .relative .text-center .transition-all .duration-300 .ease-in-out;
        }

        &__overlay {
            transform: translateY(8%);

            @apply .text-center .-z-1 .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .transition-all .duration-500 .ease-in-out;
        }

        &__title {
            @apply .transition-all .duration-500 .ease-in-out;
        }

        &:after {
            content: '';

            @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .-m-1.25 .opacity-0 .transition-all .duration-500 .ease-in-out;
        }

        &:hover,
        &:active {

            .c-member__picture {
                transform: scale(2.15); border-radius: 0; margin: 26px 0 -26px 0;

                @screen md {
                    transform: scale(2.7);
                }

                @screen lg {
                    transform: scale(2.15);
                }

                &:after {
                    @apply .opacity-30;
                }
            }

            .c-member__body {
                @apply .opacity-0 .-z-1;
            }

            .c-member__overlay {
                transform: translateY(0);

                @apply .opacity-100 .z-30;
            }

            .c-member__title {
                @apply .text-white;
            }

            .c-member:after {
                @apply .opacity-100;
            }
        }
    }
}