.c-cta {
    $c: &;

    @apply .bg-kbrv-primary;

    &__title {
        @apply .text-kbrv-tertiary .font-bold;
    }
    &__text {
        @apply .text-kbrv-secondary .font-medium;
    }
}
