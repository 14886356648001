/* purgecss start ignore */
// @tailwind base;

// @tailwind components;
@import "kbrv/01-generics/_base.scss";
@import "kbrv/03-elements/_body.scss";
@import "kbrv/03-elements/_heading.scss";
@import "kbrv/04-components/_button.scss";
@import "kbrv/04-components/_cta.scss";
@import "kbrv/04-components/_footer.scss";
@import "kbrv/04-components/_form-fields.scss";
@import "kbrv/04-components/_header.scss";
@import "kbrv/04-components/_hero.scss";
@import "kbrv/04-components/_link.scss";
@import "kbrv/04-components/_member.scss";
@import "kbrv/04-components/_nav.scss";
@import "kbrv/04-components/_outlined-title.scss";
@import "kbrv/04-components/_partner.scss";
@import "kbrv/04-components/_presentation.scss";
@import "kbrv/04-components/_slider.scss";
@import "kbrv/04-components/_text.scss";
/* purgecss end ignore */

// @tailwind utilities;
