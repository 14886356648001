.c-footer {
    @apply .bg-black;

    a {
        @apply .text-kbrv-grey;

        &:hover {
            @apply .text-kbrv-primary
        }
    }

    .social .list {
        @apply .justify-center;
    }
}