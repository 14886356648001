/* purgecss start ignore */
::selection {
  @apply .bg-kbrv-primary;
}

.grid-template-related {
  grid-template-areas: "header link" "list list";
}

.grid-template-related .grid-area-header {
  grid-area: header;
}

.grid-template-related .grid-area-link {
  grid-area: link;
}

.grid-template-related .grid-area-list {
  grid-area: list;
}

.grid-columns-3-1 {
  grid-template-columns: 3fr 1fr;
}

@media (min-width: 1024px) {
  .translate-y-calc-1_2 {
    transform: translateY(calc((100% - 14.375rem) / -2));
  }
}

.shrink-0 {
  flex-shrink: 0;
}

.after-white:after {
  content: '';
  @apply .absolute .inset-0 .bg-white;
  top: 50%;
}

img.hover {
  transform: scale(1.1);
}

.top-1_2 {
  top: 50%;
}

body {
  @apply .font-kbrv-secondary .font-medium .text-kbrv-secondary;
}

.h0,
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply .font-kbrv-primary .font-bold .text-kbrv-tertiary;
}

.h0 strong,
h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong {
  @apply .text-kbrv-primary .font-bold;
}

.text-white .h0, .text-white
h1, .text-white
h2, .text-white
h3, .text-white
h4, .text-white
h5, .text-white
h6 {
  @apply .text-white;
}

.c-button {
  @apply .font-kbrv-primary;
}

.c-button--primary {
  @apply .bg-kbrv-primary;
}

.c-button--primary .c-button__label, .c-button--primary .c-button__icon {
  @apply .text-kbrv-tertiary;
}

.c-button--primary:after {
  @apply .bg-kbrv-tertiary;
}

.c-button--primary:hover, .c-button--primary:active {
  @apply .bg-kbrv-tertiary;
}

.c-button--primary:hover .c-button__label, .c-button--primary:hover .c-button__icon, .c-button--primary:active .c-button__label, .c-button--primary:active .c-button__icon {
  @apply .text-white;
}

.c-button--secondary {
  @apply .bg-white;
}

.c-button--secondary:hover, .c-button--secondary:active {
  @apply .border-kbrv-tertiary;
}

.c-button--secondary .c-button__label {
  @apply .text-kbrv-tertiary .text-lg;
}

.c-button--tertiary {
  @apply .border-kbrv-primary;
}

.c-button--tertiary .c-button__label, .c-button--tertiary .c-button__icon {
  @apply .text-lg .text-kbrv-tertiary;
}

.c-button--tertiary:hover, .c-button--tertiary:active {
  @apply .border-kbrv-tertiary;
}

.c-cta {
  @apply .bg-kbrv-primary;
}

.c-cta__title {
  @apply .text-kbrv-tertiary .font-bold;
}

.c-cta__text {
  @apply .text-kbrv-secondary .font-medium;
}

.c-footer {
  @apply .bg-black;
}

.c-footer a {
  @apply .text-kbrv-grey;
}

.c-footer a:hover {
  @apply .text-kbrv-primary;
}

.c-footer .social .list {
  @apply .justify-center;
}

.c-form-field__label {
  @apply .text-kbrv-tertiary;
}

.c-form-field__label.is-required:after {
  @apply .font-kbrv-primary;
}

.c-page--altHeader .c-header__inner {
  @apply .border-kbrv-border;
}

.c-hero {
  @apply .bg-kbrv-secondary;
}

.c-hero h1.c-hero__title {
  @apply .text-white;
}

.c-hero__sub-title {
  @apply .text-white;
}

.c-hero--alt .c-hero__bottom {
  @apply .bg-kbrv-secondary;
}

.c-link {
  @apply .font-kbrv-primary;
}

.c-link--primary .c-link__label {
  @apply .text-kbrv-tertiary;
}

.c-link--primary:after {
  @apply .bg-kbrv-primary;
}

.c-link--secondary .c-link__label,
.c-link--secondary .c-link__icon .c-icon {
  @apply .text-kbrv-primary;
}

.c-link--tertiary:hover, .c-link--tertiary:active, .c-link--tertiary.hover {
  @apply .border-kbrv-primary;
}

section.teamMembers .c-member {
  @apply .w-full .h-full .relative .text-0;
}

section.teamMembers .c-member__wrapper {
  @apply .relative .h-full .overflow-hidden;
}

section.teamMembers .c-member__inner, section.teamMembers .c-member__overlay {
  @apply .p-10;
}

section.teamMembers .c-member__inner {
  @apply .relative .h-full .z-10 .bg-grey-lighter .transition-all .duration-300 .ease-in-out;
}

section.teamMembers .c-member__picture {
  width: 172px;
  height: 172px;
  @apply .relative .rounded-21 .overflow-hidden .z-10 .transition-picture .duration-700 .ease-in-out;
}

section.teamMembers .c-member__picture:after {
  content: '';
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .bg-secondary .transition-all .duration-300 .ease-in-out;
}

section.teamMembers .c-member__body {
  @apply .relative .text-center .transition-all .duration-300 .ease-in-out;
}

section.teamMembers .c-member__overlay {
  transform: translateY(8%);
  @apply .text-center .-z-1 .absolute .top-0 .right-0 .bottom-0 .left-0 .opacity-0 .transition-all .duration-500 .ease-in-out;
}

section.teamMembers .c-member__title {
  @apply .transition-all .duration-500 .ease-in-out;
}

section.teamMembers .c-member:after {
  content: '';
  @apply .absolute .top-0 .right-0 .bottom-0 .left-0 .-m-1.25 .opacity-0 .transition-all .duration-500 .ease-in-out;
}

section.teamMembers .c-member:hover .c-member__picture, section.teamMembers .c-member:active .c-member__picture {
  transform: scale(2.15);
  border-radius: 0;
  margin: 26px 0 -26px 0;
}

@screen md {
  section.teamMembers .c-member:hover .c-member__picture, section.teamMembers .c-member:active .c-member__picture {
    transform: scale(2.7);
  }
}

@screen lg {
  section.teamMembers .c-member:hover .c-member__picture, section.teamMembers .c-member:active .c-member__picture {
    transform: scale(2.15);
  }
}

section.teamMembers .c-member:hover .c-member__picture:after, section.teamMembers .c-member:active .c-member__picture:after {
  @apply .opacity-30;
}

section.teamMembers .c-member:hover .c-member__body, section.teamMembers .c-member:active .c-member__body {
  @apply .opacity-0 .-z-1;
}

section.teamMembers .c-member:hover .c-member__overlay, section.teamMembers .c-member:active .c-member__overlay {
  transform: translateY(0);
  @apply .opacity-100 .z-30;
}

section.teamMembers .c-member:hover .c-member__title, section.teamMembers .c-member:active .c-member__title {
  @apply .text-white;
}

section.teamMembers .c-member:hover .c-member:after, section.teamMembers .c-member:active .c-member:after {
  @apply .opacity-100;
}

.c-nav--primary .c-nav__list {
  @apply .flex .flex-col;
}

@screen lg {
  .c-nav--primary .c-nav__list {
    display: grid;
  }
}

@screen lg {
  .c-nav--primary .c-nav__item {
    grid-row: 1;
  }
}

.c-nav--primary .c-nav__item:after {
  @apply .bg-kbrv-primary;
}

@screen lg {
  .c-nav--primary .c-nav__item-wrapper {
    @apply .flex .h-full .items-center;
  }
}

@screen lg {
  .c-nav--primary .c-nav__item-inner:hover .c-nav__label-text,
  .c-nav--primary .c-nav__item-inner:hover .c-nav__label-icon .c-icon, .c-nav--primary .c-nav__item-inner:active .c-nav__label-text,
  .c-nav--primary .c-nav__item-inner:active .c-nav__label-icon .c-icon, .c-nav--primary .c-nav__item-inner.has-hover .c-nav__label-text,
  .c-nav--primary .c-nav__item-inner.has-hover .c-nav__label-icon .c-icon {
    @apply .text-kbrv-primary;
  }
}

@screen lg {
  .c-nav--primary .c-nav__sub-link:after {
    @apply .bg-kbrv-primary;
  }
  .c-nav--primary .c-nav__sub-link:hover .c-nav__sub-label, .c-nav--primary .c-nav__sub-link:active .c-nav__sub-label {
    @apply .text-kbrv-primary;
  }
}

.c-nav--primary .c-nav__sub-label {
  @apply .font-kbrv-primary;
}

.c-nav--primary .c-nav__label-text {
  @apply .font-kbrv-primary;
}

.c-nav--primary #navLang {
  right: -12px;
}

.c-nav--primary #navLang .c-nav__sub-list {
  @apply .flex-col .items-end;
}

.c-nav--primary #navLang .c-nav__sub-item {
  @apply .w-15;
}

.c-nav--primary #navLang .c-nav__sub-item:first-child .c-nav__sub-link {
  @apply .pt-5;
}

.c-nav--primary #navLang .c-nav__sub-item:last-child .c-nav__sub-link {
  @apply .pb-5;
}

.c-nav--primary #navLang .c-nav__sub-link {
  @apply .h-full .py-2.5 .border-b-0;
}

.c-nav--primary #navLang .c-nav__sub-link:hover, .c-nav--primary #navLang .c-nav__sub-link:active {
  @apply .bg-white;
}

.c-nav--primary #navLang .c-nav__sub-link:hover:after, .c-nav--primary #navLang .c-nav__sub-link:active:after {
  @apply .opacity-0;
}

.c-nav--breadcrumb .c-nav__link:hover .c-nav__label-text, .c-nav--breadcrumb .c-nav__link:active .c-nav__label-text {
  @apply .text-kbrv-primary;
}

.c-nav--legal {
  @apply .justify-center;
}

.c-nav--legal .c-nav__link:hover .c-nav__label-text, .c-nav--legal .c-nav__link:active .c-nav__label-text {
  @apply .text-kbrv-primary;
}

.c-nav--legal .c-nav__label-text {
  @apply .text-lg .font-medium;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--primary .c-nav__label-text {
  @apply .text-kbrv-tertiary;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--primary .c-nav__label-icon {
  @apply .text-kbrv-tertiary;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--primary .c-nav__sub-link {
  @apply .bg-kbrv-secondary .border-white .border-b-0;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--primary .c-nav__sub-link:hover {
  @apply .bg-kbrv-secondary;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--primary .c-nav__sub-label {
  @apply .text-white;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--primary #navLang .c-nav__sub-link {
  @apply .bg-kbrv-secondary .border-0;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--primary #navLang .c-nav__sub-link:hover {
  @apply .bg-kbrv-secondary;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--primary #navLang .c-nav__sub-label {
  @apply .text-white;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--breadcrumb .c-nav__item:after {
  @apply .text-kbrv-tertiary;
}

.c-page--altHeader > div:not(.c-mobile-nav) .c-nav--breadcrumb .c-nav__label-text {
  @apply .text-kbrv-tertiary;
}

.c-page--altHeader .hamburger-inner,
.c-page--altHeader .hamburger-inner:before,
.c-page--altHeader .hamburger-inner:after {
  @apply .bg-kbrv-tertiary;
}

.c-outlined-title {
  line-height: 210px;
  @apply .font-kbrv-primary;
}

.c-partner:hover:after, .c-partner:active:after, .c-partner.is-active:after {
  @apply .border-kbrv-primary;
}

.c-presentation__text-wrapper {
  @apply .opacity-100;
}

.c-presentation__footer {
  @apply .border-kbrv-primary;
}

.c-presentation__footer:before {
  @apply .bg-kbrv-tertiary;
}

.c-presentation:after {
  @apply .bg-kbrv-primary;
}

.c-presentation.has-image .c-presentation__picture:after {
  content: none;
}

.c-presentation:hover .c-presentation__footer:before, .c-presentation:active .c-presentation__footer:before {
  @apply .w-full .bg-white;
}

.c-slider__nav-button:hover, .c-slider__nav-button:active {
  @apply .border-kbrv-secondary;
}

.c-slider__nav-button:hover .c-icon, .c-slider__nav-button:active .c-icon {
  @apply .text-kbrv-tertiary;
}

.c-slider__nav-button--alt {
  @apply .border-kbrv-primary;
}

.c-slider__nav-button--alt .c-icon {
  @apply .text-kbrv-tertiary;
}

.c-slider__pager {
  @apply .font-kbrv-primary .text-lg .font-bold .h-12 .px-6 .rounded-full .border .border-kbrv-primary .flex .items-center .justify-center;
}

.c-slider__pager--current {
  @apply .text-kbrv-tertiary .pr-1.25;
}

.c-slider__actions {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.c-slider__actions .play,
.c-slider__actions .pause {
  @apply .p-4 .rounded-full .hidden .cursor-pointer;
}

.c-slider__actions .play.active,
.c-slider__actions .pause.active {
  @apply .block;
}

.c-slider__actions .play {
  @apply .bg-kbrv-tertiary .text-kbrv-primary;
}

.c-slider__actions .pause {
  @apply .bg-kbrv-primary .text-kbrv-tertiary;
}

.c-text--medium {
  @apply .text-lg;
}

.c-text a:after {
  @apply .bg-kbrv-primary;
}

.c-text ul li:before {
  @apply .bg-kbrv-primary;
}

.c-text ol li::marker {
  @apply .text-kbrv-primary;
}

/* purgecss end ignore */
