.c-button {
    $c: &;

    @apply .font-kbrv-primary;

    &--primary {
        @apply .bg-kbrv-primary;

        #{$c} {
            &__label,
            &__icon {
                @apply .text-kbrv-tertiary;
            }
        }

        &:after {
            @apply .bg-kbrv-tertiary;
        }

        &:hover,
        &:active {
            @apply .bg-kbrv-tertiary;

            #{$c} {
                &__label,
                &__icon {
                    @apply .text-white;
                }
            }
        }
    }

    &--secondary {
        @apply .bg-white;

        &:hover,
        &:active {
            @apply .border-kbrv-tertiary;
        }

        #{$c}__label {
            @apply .text-kbrv-tertiary .text-lg;
        }
    }

    &--tertiary {
        @apply .border-kbrv-primary;

        #{$c} {
            &__label,
            &__icon {
                @apply .text-lg .text-kbrv-tertiary;
            }

            &__label {
            }

            &__icon {
            }
        }

        &:hover,
        &:active {
            @apply .border-kbrv-tertiary;
        }
    }
}